<template>
  <ui-container
    no-gutters-x
    class="flex flex-1 h-full"
  >
    <ui-card class="flex flex-1">
      <ui-card-contet-scroll>
        <ui-back-link />
        <common-forms-create-or-update-room
          class="max-w-xl mx-auto"
          @success="onSuccess"
        />
      </ui-card-contet-scroll>
    </ui-card>
  </ui-container>
</template>

<script setup lang="ts">
  definePageMeta({
    layout: 'room',
    middleware: () => {
      const { user, subscriptionLimitations } = useAuthUser();
      const localePath = useLocalePath();
      if (
        user.value &&
        subscriptionLimitations.value &&
        user.value?.rooms_as_owner_count >= subscriptionLimitations.value?.rooms_as_owner_limit
      ) {
        return navigateTo(localePath({ name: 'dashboard-subscriptions' }));
      }
    }
  });
  const { routerPush } = useRouterLocale();
  const myRoomStore = useMyRoomStore();
  const interestsStore = useInterestsStore();
  const onSuccess = (room: Room) => {
    myRoomStore.addRoom(room, RoomFilterType.Admin);
    routerPush({
      name: 'dashboard-rooms-roomId',
      params: { roomId: room.id }
    });
  };
  onUnmounted(() => {
    interestsStore.clearData(InterestableType.ROOM);
  });

  useSeoMeta({
    title: 'Create Room'
  });
</script>
