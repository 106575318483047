<template>
  <div>
    <ui-loader
      :loading="pending"
      :error="error"
      static
    >
      <form
        class="flex flex-col lg:gap-7 gap-5 sm:gap-5"
        v-bind="$attrs"
        @submit="onSubmit"
      >
        <div class="flex flex-col gap-5">
          <common-avatar-room-update
            size="lg"
            class="mx-auto grow-0"
            :avatar-url="fileUrl || avatarPath"
            :room-name="roomName"
            @upload="onSelectAvatar"
          />
          <ui-fields-text
            name="name"
            label="Enter Your Room Name *"
          />
          <common-forms-parts-interests-list
            :data="interestsStore.roomPrivateInterests"
            :source="room"
            :interestable-type="InterestableType.ROOM"
            :hide-create="!room"
            label="Choose the Interests (minimum 3)"
          />
          <ui-fields-textarea
            name="about_author"
            label="About Author"
            required
            filed-wrap-class="flex-1"
          />
          <ui-fields-textarea
            name="about"
            required
            label="Room Description"
            placeholder="Write Your Room Description "
          />
          <div class="flex items-center gap-2.5 justify-between w-full">
            <ui-typography
              text="Show message history"
              variant="h4"
              tag="h4"
            />
            <ui-fields-switch
              name="is_visible_history"
              :checked-value="true"
              :unchecked-value="false"
            />
          </div>
        </div>
        <slot
          name="actions"
          :is-submitting="isSubmitting"
        >
          <div class="flex">
            <ui-button
              :text="room ? 'Update My Room' : 'Create My Room'"
              type="submit"
              full
              class="max-w-md mx-auto"
              :disabled="!meta.valid"
              :loading="isSubmitting"
            />
          </div>
        </slot>
      </form>
    </ui-loader>
  </div>
</template>
<script setup lang="ts">
  import { object, number } from 'yup';

  type Props = {
    room?: Room;
  };
  const props = defineProps<Props>();
  const emit = defineEmits(['success']);
  const apiRoutes = useApiRoutes();
  const interestsStore = useInterestsStore();

  const { pending, error } = await useLazyAsyncData('private-room-interests', async () => {
    const pendingInterests = interestsStore.fetchDefaultInterests();
    if (props.room) {
      await Promise.all([pendingInterests, interestsStore.fetchRoomInterests(props.room.id)]);
    } else {
      await pendingInterests;
    }
    return true;
  });
  const avatarPath = computed(() => props.room?.avatar_url);
  const roomName = computed(() => props.room?.name);
  const fileUrl = ref('');

  const initialValues = computed(() => {
    const defaultValues = {
      avatar: undefined,
      name: '',
      about_author: '',
      about: '',
      is_visible_history: true,
      interest_ids: []
    };
    return getCleanFormValue(props.room as Room, defaultValues);
  });

  const validationFields = useValidationRules();
  const limitToasts = useLimitToasts();

  const { handleSubmit, isSubmitting, meta, setFieldValue } = useForm({
    validationSchema: object({
      name: validationFields.validationTitle(),
      interest_ids: validationFields.min(validationFields.arrayOf(number()), 3, true),
      about_author: validationFields.requiredString(),
      about: validationFields.requiredString()
    }),
    initialValues
  });

  const onSubmit = handleSubmit(
    useSubmitHandler(
      form => {
        return props.room ? apiRoutes.rooms.update(props.room.id, form) : apiRoutes.rooms.create(form);
      },
      initialValues,
      {
        async onSuccess(data) {
          emit('success', data);
        },
        isShowToastError: false,
        isCleanData: true,
        successToast: true,
        onError: limitToasts
      }
    )
  );
  const onSelectAvatar = (file: File) => {
    fileUrl.value = window.URL.createObjectURL(file);
    setFieldValue('avatar', file);
  };
</script>
