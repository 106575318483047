<template>
  <ui-fields-file-validate
    name="avatar"
    hide-preview
    @select="selectAvatar"
  >
    <template #default="{ inputId }">
      <label :for="inputId">
        <div class="relative group cursor-pointer">
          <ui-avatar
            :key="avatarUrl"
            :user-name="roomName || 'room'"
            :src="avatarUrl"
            :size="size"
          >
            <div
              class="absolute bg-black opacity-35 group-hover:visible invisible inset-0 transition ease-in-out duration-300"
            />
            <ui-icon
              name="edit"
              class="text-white text-3xl absolute group-hover:visible invisible inset-0 transition ease-in-out duration-300"
            />
          </ui-avatar>
        </div>
      </label>
    </template>
  </ui-fields-file-validate>
</template>

<script setup lang="ts">
  import type { AvatarCssProps } from '~/components/ui/Avatar/styles';

  type Props = {
    size?: AvatarCssProps['size'];
    avatarUrl?: string;
    roomName?: string;
  };

  defineProps<Props>();

  const emit = defineEmits(['upload']);

  const { open: openUpdateAvatar, close: closeUpdateAvatar } = useCropRoomAvatarModal({
    onUpload: (file: File) => {
      emit('upload', file);
      closeUpdateAvatar();
    }
  });
  const selectAvatar = (file: File) => {
    openUpdateAvatar({ file });
  };
</script>
