<template>
  <ui-container
    no-gutters-x
    class="flex flex-1 h-full"
  >
    <ui-card class="flex flex-1">
      <ui-card-contet-scroll>
        <ui-back-link :to="{ name: 'dashboard-rooms-roomId', params: { roomId: $route.params.roomId } }" />
        <common-forms-create-or-update-room
          :room="roomStore.room"
          class="max-w-xl mx-auto"
          @success="onUpdate"
        />
      </ui-card-contet-scroll>
    </ui-card>
  </ui-container>
</template>

<script setup lang="ts">
  const roomStore = useRoomStore();
  const { routerReplace } = useRouterLocale();
  const interestsStore = useInterestsStore();
  if (!roomStore.userPermission.UpdateRoomDetails) {
    await routerReplace({
      name: 'dashboard-rooms-roomId',
      params: { roomId: roomStore.roomId }
    });
  }
  const onUpdate = (room: Room) => {
    roomStore.updateRoom(room);
  };
  onUnmounted(() => {
    interestsStore.clearData(InterestableType.ROOM);
  });

  useSeoMeta({
    title: 'Edit'
  });
</script>
